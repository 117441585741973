module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"gatsbyRemarkPlugins":[{"resolve":"/Users/dhanesh/myGate/mg-developer-docs/node_modules/gatsby-remark-images","id":"9a2cc80d-1fe4-5d4d-abd6-3e902d0097d7","name":"gatsby-remark-images","version":"6.25.0","modulePath":"/Users/dhanesh/myGate/mg-developer-docs/node_modules/gatsby-remark-images/index.js","pluginOptions":{"plugins":[],"maxWidth":1035,"linkImagesToOriginal":false,"sizeByPixelDensity":true},"nodeAPIs":["pluginOptionsSchema"],"browserAPIs":["onRouteUpdate"],"ssrAPIs":[]},{"resolve":"/Users/dhanesh/myGate/mg-developer-docs/node_modules/gatsby-remark-images-zoom","id":"9efe111e-a794-5c7e-9bc1-f0c5c2906aa6","name":"gatsby-remark-images-zoom","version":"1.1.0","modulePath":"/Users/dhanesh/myGate/mg-developer-docs/node_modules/gatsby-remark-images-zoom/index.js","module":{},"pluginOptions":{"plugins":[]},"nodeAPIs":[],"browserAPIs":["onClientEntry","onRouteUpdate"],"ssrAPIs":[]},{"resolve":"/Users/dhanesh/myGate/mg-developer-docs/node_modules/gatsby-remark-copy-linked-files","id":"cf104666-f93f-5e9d-9c2e-1bd9003e1e14","name":"gatsby-remark-copy-linked-files","version":"5.25.0","modulePath":"/Users/dhanesh/myGate/mg-developer-docs/node_modules/gatsby-remark-copy-linked-files/index.js","pluginOptions":{"plugins":[]},"nodeAPIs":[],"browserAPIs":[],"ssrAPIs":[]}],"extensions":[".mdx",".md"],"defaultLayouts":{},"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/Users/dhanesh/myGate/mg-developer-docs","commonmark":false,"JSFrontmatterEngine":false,"engines":{}},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1035,"linkImagesToOriginal":false,"sizeByPixelDensity":true},
    },{
      plugin: require('../node_modules/gatsby-remark-images-zoom/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":null,"head":true,"anonymize":false},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
